import { produce } from 'immer';
export const initialState = {
  dialog: undefined
};
export const reducer = (state, action) => produce(state, draft => {
  switch (action.type) {
    case 'show-dialog':
      {
        draft.dialog = action.dialog;
        return draft;
      }
    case 'remove-dialog':
      {
        draft.dialog = undefined;
        return draft;
      }
    default:
      {
        const exhaustiveCheck = action;
        throw new Error(exhaustiveCheck);
      }
  }
});